<template>
  <div class="loadingWrapper vstack gap-1">
    <div class="spinner-border" role="status"></div>
    <br />
    <div>{{ messageWhenNoItems }}</div>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    messageWhenNoItems: {
      type: String,
      default: '',
    },
  },
}
</script>
<style lang="scss" scoped></style>
